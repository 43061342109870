// Imports => React
import React, { useState, useEffect, useMemo, memo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Fade } from 'react-awesome-reveal';
import loadable from '@loadable/component';
import clsx from 'clsx';

// Imports => Constants
import { KEYS, MAP_OPTIONS, PERMISSIONS, TITLES, TYPES } from '@constants';

// Imports => Utilities
import { AcIsSet, AcFormatInternalURI, AcFormatMapURL } from '@utils';

// Imports => Hooks
import { usePermissions } from '@hooks';

// Imports => Molecules
const AcEditHammerModal = loadable(() =>
  import('@molecules/ac-edit-hammer-modal/ac-edit-hammer-modal.web')
);

// Imports => Components
const AcLocationMapWidget = loadable(() =>
  import('@components/ac-location-map-widget/ac-location-map-widget.web')
);
const AcDetailsCard = loadable(() =>
  import('@components/ac-details-card/ac-details-card.web')
);
const AcHeroVisual = loadable(() =>
  import('@components/ac-hero-visual/ac-hero-visual.web')
);

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
const AcHeading = loadable(() => import('@atoms/ac-heading/ac-heading.web'));
const AcCard = loadable(() => import('@atoms/ac-card/ac-card.web'));
const AcLoader = loadable(() => import('@atoms/ac-loader/ac-loader.web'));
import { useRenderContracts } from '@components/ac-contract-table';

const _CLASSES = {
  MAIN: 'ac-hammer-detail-overview-tab',
};

const AcHammerDetailOverviewTab = ({ data, store: { hammers, ui } }) => {
  const { can } = usePermissions();
  const contracts = useRenderContracts(data.contracts);

  const displayEditModal = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      title: `${TITLES.EDIT_HAMMER}: ${data.object_no}`,
      body: <AcEditHammerModal data={data} submit={hammers.update} />,
      closeable: true,
      visible: true,
      actions: [],
      callback: () => {
        ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
    });
  };

  const renderLocationMapWidget = useMemo(() => {
    let result = {
      location: MAP_OPTIONS.center,
    };

    if (AcIsSet(data) && AcIsSet(data.location)) {
      const { location } = data;
      result.location = location;
    }

    return <AcLocationMapWidget {...result} entity={KEYS.HAMMERS} />;
  }, [data]);

  const renderDetails = useMemo(() => {
    if (!data) return null;

    const { equipment_type, object_no, company, location } = data;

    let items = [
      {
        label: 'Object number',
        value: object_no || '-',
      },
    ];

    let object_type = {
      label: 'Object type',
      value: (equipment_type && equipment_type.name) || '-',
    };

    if (can(PERMISSIONS.EQUIPMENT_TYPE.READ)) {
      const object_route =
        (equipment_type &&
          AcFormatInternalURI(
            {
              id: equipment_type.id,
              entity: KEYS.HAMMER_TYPES,
              equipment_group: KEYS.HAMMERS,
            },
            'View object type'
          )) ||
        null;

      if (object_route)
        object_type = { ...object_type, type: TYPES.LINK, to: object_route };
    }
    items.push(object_type);

    if (can(PERMISSIONS.EQUIPMENT.READ_ALL)) {
      const company_route =
        (company &&
          AcFormatInternalURI(
            { id: company.id, entity: KEYS.COMPANY },
            'View company'
          )) ||
        null;

      items.push({
        label: 'Owned by',
        value: (company && company.name) || '-',
        type: TYPES.LINK,
        to: company_route,
      });
    }

    if (AcIsSet(location)) {
      const map = {
        location: location && [location.lat, location.lng].join(', '),
        url: AcFormatMapURL(location),
      };
      const maplink =
        (map.url &&
          `<a href="${map.url}" target="_blank"><span>${map.location}</span><i class="ac-icon ac-icon--open-in-new" /></a>`) ||
        '-';

      items.push({
        label: 'Location',
        value: maplink || '-',
      });
    }

    const edit = can(PERMISSIONS.EQUIPMENT.MANAGE_DETAILS) && displayEditModal;

    return <AcDetailsCard title={TITLES.DETAILS} items={items} edit={edit} />;
  }, [data]);

  const renderHeroVisual = useMemo(() => {
    if (!data) return null;

    const { equipment_type } = data;

    if (!equipment_type || !equipment_type.header_image) return null;

    return <AcHeroVisual image={equipment_type.header_image} />;
  }, [data]);

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN);
  });

  return (
    <div className={getMainClassNames}>
      <AcContainer fluid>
        <AcRow>
          <AcColumn>{renderLocationMapWidget}</AcColumn>
        </AcRow>

        <AcRow>
          <AcColumn xs={12} sm={6}>
            <AcRow>
              <AcColumn>
                <AcHeading tag={'h2'} rank={5} className={'h-margin-bottom-25'}>
                  Hammer details
                </AcHeading>
              </AcColumn>
            </AcRow>
            <AcRow>
              <AcColumn>{renderDetails}</AcColumn>
            </AcRow>
          </AcColumn>

          {renderHeroVisual && (
            <AcColumn xs={12} sm={6}>
              <AcRow>
                <AcColumn>
                  <AcHeading
                    tag={'h2'}
                    rank={5}
                    className={'h-margin-bottom-25'}
                  >
                    Hammer visualisation
                  </AcHeading>
                </AcColumn>
              </AcRow>

              <AcRow>
                <AcColumn>{renderHeroVisual}</AcColumn>
              </AcRow>
            </AcColumn>
          )}
        </AcRow>

        {contracts && (
          <AcRow>
            <AcColumn xs={12} sm={12}>
              <AcRow>
                <AcColumn>
                  <AcHeading
                    tag={'h2'}
                    rank={5}
                    className={'h-margin-bottom-25'}
                  >
                    Contracts
                  </AcHeading>
                </AcColumn>
              </AcRow>
              <AcRow>
                <AcColumn>
                  <AcCard dense flat>
                    {contracts}
                  </AcCard>
                </AcColumn>
              </AcRow>
            </AcColumn>
          </AcRow>
        )}
      </AcContainer>
    </div>
  );
};

export default withStore(observer(AcHammerDetailOverviewTab));
